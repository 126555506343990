<template>
  <div class="all">
    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <HeaderFilter
        :isContentProgramming="true"
        :dateFilterContentProgramming="dateFilter"
        @changeFilterMonth="changeFilterMonth"
      />

      <div v-show="loading" class="flex-center mt-3">
        <div class="loading2"></div>
      </div>
      <div class="home container mt-2" v-show="!loading && themes.length <= 0">
        <span class="warning"> Não há conteúdo referente a esta data. </span>
      </div>
      <div class="home container mt-1" v-show="!loading && themes.length > 0">
        <div class="itens-holder">
          <div
            v-for="(theme, index) in themes"
            :key="`theme${index}`"
            @click="getTemplates(theme)"
            class="item pointer"
            :class="{
              active: selectedTheme.date == theme.date,
            }"
          >
            <div class="day">
              <span>{{ theme.date | moment("DD") }}</span>
            </div>
            <div class="description">{{ theme.themeName }}</div>
          </div>
        </div>
        <div v-show="loadingTemplates" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>
        <div class="flex flex-start">
          <div
            v-show="!loadingTemplates && !loading"
            class="title mt-2-desktop mb-2-desktop mb-1-mobile mb-1-tablet"
          >
            {{ selectedTheme.date | moment("DD [de] MMMM") }}:
            {{ selectedTheme.themeName }}
          </div>
        </div>
        <transition
          v-show="!loadingTemplates && !loading && templates.length > 0"
        >
          <div
            id="gallery"
            v-masonry="'gallery'"
            :gutter="10"
            transition-duration="0.3s"
            item-selector=".block-item"
          >
            <div
              v-masonry-tile
              class="block-item"
              :class="{ stories: item.type == 'stories' }"
              v-for="item in templates"
              :key="item.id"
            >
              <span class="type">{{ item.type }}</span>
              <router-link
                :to="`/conteudo/${item.id}`"
                class="img-holder"
                :style="`background-image:url(${item.image});`"
              ></router-link>
            </div>
          </div>
        </transition>

        <div v-show="!loadingTemplates && !loading && templates.length < 1">
          <span class="warning">
            {{ t("Não há conteúdo referente a esta data.") }}
          </span>
        </div>

        <div
          v-show="page < totalPages"
          @click="getMore"
          class="btn mt-2 desktop4 mx-auto"
        >
          <span v-show="!loadingMore">{{ t("Ver mais") }}</span>
          <div v-show="loadingMore" class="loading2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import authentication from "../util/authentication";
import HeaderFilter from "../components/HeaderFilter";
import Navbar from "../components/Navbar";
export default {
  components: {
    Navbar,
    HeaderFilter,
  },
  data() {
    return {
      loading: true,
      loadingTemplates: false,
      loadingMore: false,
      showDailyInsight: false,
      templates: [],
      page: 1,
      take: 10,
      totalPages: 1,
      themes: [],
      selectedTheme: {},
      dateFilter: this.$moment().format("YYYY-MM"),
    };
  },
  computed: {
    isAppleUser() {
      return authentication.getUser().info.userType === "Apple";
    },
    isUserPremium() {
      return authentication.getUser().info.premium;
    },
    userPlanLink() {
      return this.isAppleUser ? "/assinatura/apple" : "/meu-plano";
    },
  },
  created() {
    this.verifySubscription();
  },
  methods: {
    verifySubscription() {
      if (this.isAppleUser) {
        var receipt = state?.subscription?.transaction?.appStoreReceipt || "";
        this.getSubscription(receipt);
      } else {
        this.getSubscription("");
      }
    },
    getSubscription(receipt) {
      http
        .post("user/subscription", { receipt }, authentication.getHeaders())
        .then((response) => {
          var subscription = response.data;
          // if (!subscription.planDetails.isValid && !this.isUserPremium) {
          //   this.$router.push(this.userPlanLink);
          // }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.getThemeTemplate();
        });
    },
    mergeThemesWithSameDate() {
      for (let i = 0; i < this.themes.length - 1; i++) {
        if (this.themes[i].date === this.themes[i + 1].date) {
          this.themes[i].themeName =
            this.themes[i]?.themeName + " / " + this.themes[i + 1]?.themeName;
          this.themes[i].themeNameEs =
            this.themes[i]?.themeNameEs +
            " / " +
            this.themes[i + 1]?.themeNameEs;
          this.themes.splice(i + 1, 1);
        }
      }
      this.getTemplates(this.themes[0]);
    },
    getThemeTemplate() {
      const filterMonth = this.$moment(this.dateFilter).format("MM");
      const filterYear = this.$moment(this.dateFilter).format("YYYY");

      this.loading = true;
      http
        .get(
          `template/ThemeDates?month=${filterMonth}&year=${filterYear}`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.themes = response?.data;
          if (this.themes.length > 0) this.mergeThemesWithSameDate();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTemplates(selectedTheme) {
      this.selectedTheme = selectedTheme;
      this.loadingTemplates = true;

      http
        .get(
          `template?date=${this.selectedTheme.date}`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.templates = this.addImageField(response.data.list);
          this.totalPages = response.data.pages;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$redrawVueMasonry("gallery");
          this.loadingTemplates = false;
        });
    },
    changeFilterMonth(action) {
      if (action == "next") {
        this.dateFilter = this.$moment(this.dateFilter)
          .add(1, "month")
          .format("YYYY-MM");
      } else {
        this.dateFilter = this.$moment(this.dateFilter)
          .add(-1, "month")
          .format("YYYY-MM");
      }

      this.resetItems();
      this.getThemeTemplate();
    },
    resetItems() {
      this.themes = [];
      this.templates = [];
      this.totalPages = 1;
      this.selectedTheme = {};
    },
    addImageField(list) {
      return list.map((obj) => {
        obj.image = obj.templateStyle[0].image;
        return obj;
      });
    },
    getMore() {
      this.loadingTemplates = true;
      this.page = this.page + 1;
      http
        .get(
          `template?date=${this.selectedTheme.date}`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.templates = [
            ...this.templates,
            ...this.addImageField(response.data.list),
          ];
          this.totalPages = response.data.pages;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$redrawVueMasonry("gallery");
          this.loadingTemplates = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: var(--secondary) !important;
}

.page {
  position: relative;
  padding-bottom: 45px;
}

.home.container {
  width: 90%;
  max-width: initial;
}

.loading-center {
  left: 50%;
  margin-left: -8px;
  position: relative;
}

.itens-holder {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-auto-flow: column;
  gap: 1.2rem;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.2em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    border-radius: 12px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: var(--primary);
  }
  .item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    &.active {
      .day {
        background-color: #1782fd;
        span {
          color: #fff;
        }
      }
      .description {
        color: #1782fd;
        font-family: fontRegular;
      }
    }
    .day {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 1px solid var(--primary);
      border-radius: 100%;
      span {
        font-family: fontBold;
        color: var(--primary);
      }
    }
    .description {
      position: relative;
      font-family: fontLight;
      color: #636363;
      font-size: 1.05rem;
    }
  }
}

.block-item {
  position: relative;
  width: calc(25% - 10px);
  margin-bottom: 10px;
  @media screen AND (max-width: 1021px) {
    min-width: initial;
    width: calc(33.333% - 10px);
  }
  @media screen AND (max-width: 720px) {
    min-width: initial;
    width: calc(50% - 10px);
  }
  @media screen AND (max-width: 590px) {
    min-width: initial;
    width: calc(50% - 10px);
  }
  .type {
    font-size: 1.1em;
    margin-left: 5px;
  }
  .img-holder {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background-color: #333;
    border-radius: var(--default-border-radius);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.stories {
    .img-holder {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 177.777%;
      h1 {
        color: #fff;
      }
    }
  }
}

.divider {
  height: 1.75px;
  margin: 0 auto;
  background-color: #eee;
}

.videos-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1.5em;
  iframe {
    position: relative;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 16/9;
  }
}
</style>
