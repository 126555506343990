import { render, staticRenderFns } from "./ContentCalendar.vue?vue&type=template&id=f3623510&scoped=true&"
import script from "./ContentCalendar.vue?vue&type=script&lang=js&"
export * from "./ContentCalendar.vue?vue&type=script&lang=js&"
import style0 from "./ContentCalendar.vue?vue&type=style&index=0&id=f3623510&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3623510",
  null
  
)

export default component.exports